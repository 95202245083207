import * as React from 'react';

import {
	Button,
	Card,
	Container,
	Field,
	Input,
	Label,
	Textarea,
} from '@ao-internal/components-react';

import BackgroundVideo from 'react-background-video-player';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { decode } from 'he';
import { graphql } from 'gatsby';
import { sendGtagEvent } from '../helpers/helpers';
import { useForm } from 'react-hook-form';

export interface SubmitQuestionProps {
	data: {
		wpPage: {
			title: string;
			content: string;
		};
		allWpFlamingoInboundMessage: {
			edges: {
				node: {
					title: string;
					content: string;
				};
			}[];
		};
	};
}

export interface FieldValues {
	question: string;
}

export const pageQuery = graphql`
	query submitQuestionPageQuery {
		wpPage(slug: { eq: "submit-question" }) {
			title
			content
		}
		allWpFlamingoInboundMessage(
			sort: { order: DESC, fields: date }
			limit: 50
			filter: {
				title: { eq: "Amitai Schleier Question" }
				content: { regex: "/questionStart/" }
			}
		) {
			edges {
				node {
					title
					content
				}
			}
		}
	}
`;

export const SubmitQuestionPage: React.SFC<SubmitQuestionProps> = ({
	data: { wpPage, allWpFlamingoInboundMessage },
}) => {
	const headerVideo = require('../images/submit-question-bg.mp4');
	const videoThumb = require('../images/submit-question-bg-thumb.png');

	const [pageState, setPageState] = React.useState<string>('submit-question');
	const [formState, setFormState] = React.useState<string>('');
	const [latestQuestion, setLatestQuestion] = React.useState<string>('');
	const { handleSubmit, register, errors, setError, clearErrors } = useForm({
		reValidateMode: 'onBlur',
	});

	let orderId: string;
	let urlParams: URLSearchParams | string | null;

	if (typeof window !== 'undefined') {
		urlParams = new URLSearchParams(window.location.search);
		orderId = urlParams.get('id') ? (urlParams.get('id') as string) : '';
	}

	const onSubmit = (values: FieldValues) => {
		if (!process.env.GATSBY_SUBMIT_QUESTION_ENDPOINT) return;

		const question = values.question;
		if (!question) return;

		clearErrors();
		setFormState('loading');
		if (question) setLatestQuestion(question);

		const questionFormData = question
			? `questionStart: ${question} :questionEnd`
			: '';
		const formData = new FormData();

		formData.append('your-question', questionFormData);
		formData.append('your-subject', 'Amitai Schleier Question');
		formData.append('your-id', orderId);

		fetch(process.env.GATSBY_SUBMIT_QUESTION_ENDPOINT, {
			method: 'POST',
			body: formData,
		})
			.then((response) => {
				response.text();
			})
			.then((result) => {
				setFormState('success');

				sendGtagEvent(
					'Submit question',
					'Amitai event',
					question ? question : '',
					0
				);

				setPageState('question-wall');
			})
			.catch((error) => {
				// console.log('error', error);
				setError('submit', {
					type: 'manual',
					message: error,
				});
			});
	};

	const submittedQuestions = allWpFlamingoInboundMessage.edges;

	const findQuestionInContent = (content: string) => {
		const questionRegex = /questionStart\: ([\s\S]*?) \:questionEnd/g;
		const questionArr = questionRegex.exec(content);
		let question = '';
		if (questionArr) question = questionArr[1].toString();

		const formattedQuestion = decode(question ? question : '');

		return formattedQuestion;
	};

	return (
		<React.Fragment>
			<Helmet title={decode(wpPage.title)} />
			{pageState === 'submit-question' && (
				<div className="submit-question blog-page bg-burn p-4 -m-4 relative max-h-full">
					<div
						className="video-background bg-burn"
						style={{
							backgroundImage: `url(${videoThumb})`,
						}}>
						<div className="relative max-h-full h-full">
							<BackgroundVideo
								src={headerVideo}
								containerWidth={100}
								containerHeight={100}
								autoPlay={true}
								muted={true}
								loop={false}
								playsinline={true}
								poster={videoThumb}
							/>
							<div className="video-content p-4">
								<div className="max-w-2xl">
									<h1 className="thanks text-display text-center text-white mb-8 leading-none">
										{wpPage.title}
									</h1>
									<div
										className="text-body text-center text-white"
										dangerouslySetInnerHTML={{
											__html: wpPage.content,
										}}
									/>
									<div className="mx-auto max-w-4xl">
										<Container
											as="form"
											onSubmit={handleSubmit<FieldValues>(onSubmit)}
											noValidate
											className="text-center">
											<Field
												name="question"
												// eslint-disable-next-line no-undefined
												state={errors.question ? 'error' : undefined}
												className="max-w-4xl">
												{({ inputProps, labelProps, messageProps }) => (
													<React.Fragment>
														<Label
															{...labelProps}
															htmlFor="your-question"
															className="text-white font-bold">
															My question for Amitai is...
														</Label>
														<Textarea
															{...inputProps}
															id="your-question"
															placeholder="Question:"
															ref={register({
																required: 'Please enter a question for Amitai.',
															})}
														/>
													</React.Fragment>
												)}
											</Field>
											<Button
												color="primary"
												className="bg-toast border-0 text-burn"
												type="submit"
												disabled={formState === 'loading'}>
												Submit{' '}
												{formState === 'loading' && (
													<svg
														className="animate-spin ml-4 h-5 w-5 text-white"
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24">
														<circle
															className="opacity-25"
															cx="12"
															cy="12"
															r="10"
															stroke="currentColor"
															strokeWidth="4"></circle>
														<path
															className="opacity-75"
															fill="currentColor"
															d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
													</svg>
												)}
											</Button>
											{Object.keys(errors).length > 0 && (
												<Card state="error" className="mt-4">
													{errors.question?.message}
													{!errors.question?.message &&
														'Sorry, there was a problem. Please try again.'}
												</Card>
											)}
										</Container>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{pageState === 'question-wall' && (
				<div className="question-wall blog-page bg-burn -m-4 relative max-h-full">
					<div className="overlay absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center z-20">
						<div className="p-4 text-center text-white">
							<h2 className="text-display text-white pb-4">
								Fantastic, thanks for your question. See you on the big day.
							</h2>
							<p>Don't forget to save the date in your calendar!</p>
						</div>
					</div>
					<div className="questions py-2 absolute top-0 bottom-0 right-0 left-0 z-10 overflow-hidden">
						<div className="flex flex-wrap">
							{latestQuestion && (
								<div key="0" className="w-full sm:w-1/2 md:1/3 lg:w-1/4 p-3">
									<Card className="bg-transparent text-body text-white h-full">
										{decode(latestQuestion)}
									</Card>
								</div>
							)}
							{submittedQuestions.length > 0 &&
								submittedQuestions.map((question, i) => (
									<div key={i} className="w-full sm:w-1/2 md:1/3 lg:w-1/4 p-3">
										<Card className="bg-transparent text-body text-white h-full">
											{findQuestionInContent(question.node.content)}
										</Card>
									</div>
								))}
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default function (props: SubmitQuestionProps) {
	return (
		<Layout>
			<SubmitQuestionPage {...props} />
		</Layout>
	);
}
